import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Person, PeopleGrid } from "../components/people_grid"

const BOARD_MEMBERS: Person[] = [{ name: "Nicolas Granatino", organization: "StemAI", imageName: "nicolas_granatino"}, { name: "Mehdi Ghissassi", organization: "Google DeepMind", imageName: "mehdi_ghissassi" }, { name: "Severine Gregoire", organization: "Zebox Ventures", imageName: "severine_gregoire" }];

const ADVISORS: Person[] = [{ name: "Antoine Bordes", organization: "Helsing", imageName: "antoine_bordes" }, { name: "Clement Delangue", organization: "Hugging Face", imageName: "clement_delangue" }, { name: "Alexandre LeBrun", organization: "Wit.ai, Nabla", imageName: "alexandre_lebrun" }, { name: "Pierre Feligioni", organization: "QuantHouse, Signature.it", imageName: "pierre_feligioni" }, { name: "Karim Beguir", organization: "Instadeep", imageName: "karim_beguir" }, { name: "Stanislas Polu", organization: "Dust.ai", imageName: "stanislas_polu" }, { name: "Olivier Pomel", organization: "Datadog", imageName: "olivier_pomel" }, { name: "Matthieu Rouif", organization: "PhotoRoom", imageName: "matthieu_rouif" }, { name: "Henri Seydoux", organization: "Parrot", imageName: "henri_seydoux" }, { name: "Martin Varsavsky", organization: "Overture Life, Goggo Network", imageName: "martin_varsavsky" }];

const CoreTeamPage = () => {
  return (
    <Layout>
      <Seo title="Our team" />
      <h1>Board members</h1>
      {PeopleGrid(BOARD_MEMBERS)}
      <h1>Advisors</h1>
      {PeopleGrid(ADVISORS, /*small=*/true)}
    </Layout>
  )
}

export default CoreTeamPage
